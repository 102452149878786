.leaflet-tooltip{
    background-color: transparent;
    border:none;
    font-size: 18px;
    color: white;
    box-shadow: none;
}
.leaflet-interactive{
  z-index: 9999;
}

.leaflet-tooltip:not(.show-tooltip){
    -webkit-transition: fadeIn 1s ease-out reverse;
       -moz-transition: fadeIn 1s ease-out reverse;
        -ms-transition: fadeIn 1s ease-out reverse;
         -o-transition: fadeIn 1s ease-out reverse;
         transition: fadeIn 1s ease-out reverse; 
}
.show-tooltip.leaflet-tooltip{
    -webkit-animation: fadeIn 1s ease-in;
       -moz-animation: fadeIn 1s ease-in;
        -ms-animation: fadeIn 1s ease-in;
         -o-animation: fadeIn 1s ease-in;
         animation: fadeIn 1s ease-in;
}

.show-tooltip p{
  text-align: center;
}

.leaflet-editing-icon{
  /*display: none;*/
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
}
.leaflet-draw-guide-dash{
  opacity: 1.0;
  width: 6px;
  height: 6px;
}
.leaflet-container a.leaflet-popup-close-button{
  color: #34A853;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  background-color: #D6EDDC;
}
.leaflet-popup-content-wrapper{
  border-radius: 0;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
  border-radius: 0px;
  border-style: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/*.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker{
  display: none;
}*/
/*.leaflet-right .leaflet-draw-actions li:first-child a {
  display: none;
}*/
.leaflet-touch .leaflet-bar a:first-child{
  border-radius: 0px;
}
.leaflet-touch .leaflet-bar a:last-child{
  border-radius: 0px;
}
/*.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove{
  display: none;
}*/
.css-yk16xz-control {
  background-color: transparent !important;
  border-style: none !important;
  border-bottom-style: solid !important;
  border-radius: 0 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  border-bottom-color: #8c8c8c !important;
}
.css-yk16xz-control:hover{
  border-bottom-width: 2px;
  border-bottom-color: #000000 !important;
} 
.css-1pahdxg-control{
  background-color: transparent !important;
  box-shadow: none !important;
  border-style: none !important;
  border-bottom-style: solid !important;
  border-radius: 0 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  border-bottom-color: #000000 !important;
  border-width: 2px !important;
}
.css-26l3qy-menu{
  border-radius: 0 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  box-shadow: 2px 2px 2px #888888 !important;
  border: 1px #888888 !important;
}

#tooltip{
  display: none;
  position: absolute;
  background: #666;
  color: white;
  opacity: 0.8;
  padding: 10px;
  border: 1px dashed #999;
  font-family: sans-serif;
  font-size: 12px;
  height: 35px;
  z-index: 1000;
}

.treeMarker{
  z-index: 10000;
}

.leaflet-tooltip-left{
  margin-left: -13px;
}
.leaflet-tooltip-right{
  margin-left: 15px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 1px;
  border-radius: 6px;
  background-color: #34A853 !important;
}

.rc-slider-handle {
  border: solid 2px #34A853 !important;
}

.leaflet-interactive.crosshair-cursor-enabled {
    cursor:crosshair !important;
}

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  .hide-vertex-marker .leaflet-vertex-icon, .hide-vertex-marker .leaflet-middle-icon, .vertex-hidden{
    display: none
  }
