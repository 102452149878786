[data-tour-elem="badge"] {
  font-family: 'Roboto' !important;
  font-size: 12px !important;
}

[data-tour-elem="controls"]  {
  justify-content: flex-end;
}


#simple-popover-annotation {
  margin-left: -490px !important;
  margin-top: -290px !important;
}

.reactour__helper {
  max-width: 800px !important;
}

#planting-density-link {
  color: rgb(40, 137, 212);
  text-decoration: underline;
}

#planting-density-link:hover { 
  cursor: pointer;
}
